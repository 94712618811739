<script>
import DefaultMixin from '../mixin'
import Pedidos from "components/fenaju/components/dashboard/Pedidos.vue"
import RequerAtencao from "components/fenaju/components/dashboard/RequerAtencao.vue"
import Stats from "components/fenaju/components/dashboard/Stats.vue"
import Leitor from "components/fenaju/components/dashboard/Leitor.vue";
import Resumo from "components/fenaju/components/dashboard/Resumo.vue";
import ClientesOs from "components/fenaju/components/clientes/dashboard/OrdemServico.vue";

export default {
  name: 'FenajuDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    ClientesOs,
    Resumo,
    Leitor,
    Stats,
    RequerAtencao,
    Pedidos
  },
  data() {
    return {
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Fenaju',
    name: 'Fenaju'
  }
}
</script>

<template>
    <div class="fenaju-dashboard">
      <!--<div class="ev2-menu">
        <div class="content">
          <div class="tab">
            <div class="title">Operador ID</div>
            <div class="val">232</div>
          </div>
          <div class="tab">
            <div class="title">Operador</div>
            <div class="val">TIAGO FELIPE</div>
          </div>
          <div class="tab">
            <div class="title">
              Instituição
              <i class="fal fa-circle-question">
                <u-tooltip :offset="[5,5]">Instituição que disponibilizou o acesso</u-tooltip>
              </i>
            </div>
            <div class="val">FENAJU</div>
          </div>
          <div class="tab full text-right">
            <u-btn label="Novo Registro" class="f-btn" no-caps />
          </div>
        </div>
      </div>-->
      <div class="ev2-body">
        <div class="content-limit">
          <div class="content flex">
            <pedidos />
            <requer-atencao />
            <stats />
          </div>
          <div class="content flex">
            <clientes-os />
          </div>
          <div class="content flex">
            <leitor />
            <resumo />
          </div>
        </div>
      </div>
    </div>
</template>
